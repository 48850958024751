type Props = {
  width?: string;
  height?: string;
  color?: string;
};
export const PlayIcon = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.10321 6.20808L16.3158 10.729C17.2281 11.3 17.2281 12.7 16.3158 13.271L9.10321 17.7919C8.17423 18.3748 7 17.6639 7 16.5199V7.48008C7 6.33607 8.17423 5.62525 9.10321 6.20808V6.20808Z"
        fill={color}
      />
    </svg>
  );
};
